import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useFormik } from "formik";

import Card from "../../components/Card";
import Select from "../../components/select";
import Input from "../../components/input";
import Button from "../../components/button";
import Modal from "../../components/modal";
import SelectCrypto from "../../components/selectCrypto";

import { useQuery } from '../../hooks';
import { getPaymentAction } from "../../redux/actionsCreators";
import { showModal } from "../../utils/helpers";
import { FIELDS_NAME, initialValues, validationSchema } from "./utils";
import Constants from "../../utils/constants";
import { getQuotation, getModalSuccessMessage } from "../../service/EntitiesService/paymentsService";

import "./styles/index.scss";

const {
  INPUTS_TYPES: { AMOUNT },
  BUTTON: { NEXT },
} = Constants;

const Payments = (props) => {
  const query = useQuery();
  const integrationToken = query.get("token");
  const currencies = useSelector((state) => state.Payments.currencies);
  const [showTooltip, setShowTooltip] = useState(false)
  const [cryptoNetworkShow, setCryptoNetworkShow] = useState('')
  const [quotation, setQuotation] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const { form: { operation_data: operationData = {} } = {} } = useSelector(
    (state) => state.Payments.config
  );
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (valuesForm) => {
      const dataToSend = {
        crypto_network_id: valuesForm[FIELDS_NAME.CRYPTO_RED],
        fiat: valuesForm[FIELDS_NAME.CURRENCY],
        amount: valuesForm[FIELDS_NAME.AMOUNT],
      };

      dispatch(getPaymentAction(dataToSend));
    },
    [dispatch]
  );

  const {
    values,
    handleSubmit: submitForm,
    setFieldValue,
    dirty,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handleSubmit,
  });

  const handleChange = useCallback(
    (fieldName, parseVal) => (value) => {
      const currentVal = parseVal ? parseVal(value) : value;

      setFieldValue(fieldName, currentVal);
    },
    [setFieldValue]
  );

  const parseCryptoRed = useCallback(
    (currentVal) => {
      const parseVal = currentVal ? currentVal.split("-") : []

      setCryptoNetworkShow(parseVal[0])

      return parseVal?.[2] ?? ''
    },
    []
  );

  const parseCurrency = useCallback(
    (currentVal) => currentVal?.value ?? "",
    []
  );

  const show = showModal(props.location.pathname);

  const cryptoNetworkId = useMemo(() => values[FIELDS_NAME.CRYPTO_RED], [values])
  const fiat = useMemo(() => values[FIELDS_NAME.CURRENCY], [values])

  useEffect(() => {
    if (
      Boolean(cryptoNetworkId) &&
      Boolean(fiat)
    ) {
      setShowTooltip(false);
      getQuotation({ crypto_network_id: cryptoNetworkId, fiat }, integrationToken).then(
        (response) => {
          setQuotation(response?.response?.data?.quotation ?? null)
          setShowTooltip(true)
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoNetworkId, fiat]);

  useEffect(() => {
    getModalSuccessMessage(integrationToken).then(response => {
      setSuccessMessage(response.response.data)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card className="payments">
      <div className="payments-container">
        <div className="payments-content">
          <h1 className="payments-ttile">{operationData.title}</h1>
          <div className="form-group">
            <h3 className="payments-labels">Criptomoneda y red de pago</h3>
            <SelectCrypto
              handleCrypto={handleChange(
                FIELDS_NAME.CRYPTO_RED,
                parseCryptoRed
              )}
            />
            {showTooltip && quotation && (
              <>
                <span
                  data-tip="Recuerda que la cotizacion puede variar al momento de concretar el pago."
                  className="payments-tooltip"
                >
                  1 {cryptoNetworkShow} ≈ {quotation} {fiat}
                </span>

                <ReactTooltip
                  className="tooltip"
                  place="right"
                  type="light"
                  textColor="#6E49AE"
                />
              </>
            )}
          </div>
        </div>
        <div className="payments-content">
          <div className="form-group">
            <h3 className="payments-labels">Seleccione la moneda</h3>
            <Select
              getSelect={handleChange(FIELDS_NAME.CURRENCY, parseCurrency)}
              options={currencies}
            />
          </div>
        </div>
        <div className="payments-content">
          <div className="form-group">
            <h3 className="payments-labels">Ingrese el monto</h3>
            <Input
              placeholder=""
              type={AMOUNT}
              getValue={handleChange(FIELDS_NAME.AMOUNT, parseFloat)}
            />
          </div>
        </div>
        <div className="payments-content">
          <div className="payments-right__button">
            <Button
              title={NEXT}
              type="primary"
              disabled={!dirty || !isValid}
              callbak={submitForm}
            />
          </div>
        </div>
      </div>
      <Modal status={show.status} show={show.show} message={successMessage} />
    </Card>
  );
};

export default Payments;
